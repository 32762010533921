import { Box, Typography } from "@mui/material";
import useStore from "@store";

const Footer: React.FC = () => {
  const { customerConfiguration } = useStore();
  return (
    <Box id="Footer" component="section" mt={3}>
      {customerConfiguration?.features?.copyright && (
        <Box component={"p"} sx={{ fontFamily: "Consolas, Liberation Mono, Menlo, Courier, monospace", fontSize: "12px" }}>
          powered by{" "}
          <Box component="a" href="#" sx={{ color: "#F60", pointerEvents: "none", textDecoration: "none" }} title="https://carzilla.de/" rel="noopener noreferrer">
            carzilla.de
          </Box>
        </Box>
      )}
      <Typography color={"transparent"} align="right" fontSize={"12px"}>
        V {import.meta.env.VITE_APP_VERSION}
      </Typography>
    </Box>
  );
};

export default Footer;
