import { ThemeOptions } from "@mui/material/styles";

//colors
const colorCommon = "rgba(0, 0, 0, 0.7)";
const colorPrimary = "#FF00FF";
const colorSecondary = "#000000";
const colorError = "#d32f2f";
const colorWarning = "#ffa726";
const colorInfo = "#FF00FF";
const colorSuccess = "#2e7d32";

//textSizes
const fontSizeH1 = "28px";
const fontSizeH2 = "28px";
const fontSizeH3 = "50px";
const fontSizeH4 = "18px";
const fontSizeH5 = "16px";
const fontSizeH6 = "14px";
const fontSizeSubtitle1 = "19px";
const fontSizeSubtitle2 = "11px";
const fontSizeBody1 = "15px";
const fontSizeBody2 = "13px";
const fontSizeButton = "16px";
const fontSizeOverline = "10px";
const fontSizeCaption = "12px";

// cards
const cardBorder = "1px solid";
const cardBorderColor = "#eee";
const cardBorderRadius = "4px";
const cardShadow = "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12),2px 2px 2px 2px rgba(0, 0, 0, 0.1)";
const cardShadowHover = "0px 5px 14px rgba(0, 0, 0, 0.3)";

// buttons
const buttonRadius = "2px";

//others
const colorChipAvailableGood = "#66BB6A";
const colorChipAvailableBad = "#b7b9b2";

export const themeAudi: ThemeOptions = {
  spacing: 8,
  palette: {
    common: {
      black: colorCommon
    },
    primary: {
      main: colorPrimary
    },
    secondary: {
      main: colorSecondary
    },
    error: {
      main: colorError
    },
    warning: {
      main: colorWarning
    },
    info: {
      main: colorInfo
    },
    success: {
      main: colorSuccess
    }
  },

  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,

    h1: {
      fontSize: fontSizeH1
    },
    h2: {
      fontSize: fontSizeH2
    },
    h3: {
      fontSize: fontSizeH3
    },
    h4: {
      fontSize: fontSizeH4
    },
    h5: {
      fontSize: fontSizeH5
    },
    h6: {
      fontSize: fontSizeH6
    },
    subtitle1: {
      fontSize: fontSizeSubtitle1
    },
    subtitle2: {
      fontSize: fontSizeSubtitle2
    },
    body1: {
      fontSize: fontSizeBody1
    },
    body2: {
      fontSize: fontSizeBody2
    },
    button: {
      fontSize: fontSizeButton,
      fontWeight: "bold"
    },
    overline: {
      fontSize: fontSizeOverline
    },
    caption: {
      fontSize: fontSizeCaption
    }
  },

  // das sind die DefaultWerte
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },

  transitions: {
    create: () => "none"
  },

  components: {
    MuiFormControlLabel: {
      styleOverrides: {}
    },

    MuiPagination: {
      styleOverrides: {}
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: buttonRadius,
          whiteSpace: "break-spaces"
        },
        contained: {
          "&:hover": {
            color: "white"
          }
        }
      }
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
          padding: 0,
          square: true
        },
        outlined: {
          border: cardBorder,
          borderColor: cardBorderColor,
          borderRadius: cardBorderRadius,
          padding: 0,
          square: true
        }
      }
    },

    MuiCard: {
      styleOverrides: {
        root: {
          border: cardBorder,
          borderColor: cardBorderColor,
          borderRadius: cardBorderRadius,
          padding: 0,
          boxShadow: cardShadow,
          "&.clickable:hover": {
            boxShadow: cardShadowHover,
            transition: "250ms all ease-in;"
          }
        }
      }
    },

    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: "h3", textTransform: "capitalize" },
        subheaderTypographyProps: { variant: "body1" }
      },
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: cardBorderRadius,
          padding: 8
        }
      }
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 8
        }
      }
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
          "& Button": {
            color: "#fff !important"
          }
        }
      }
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          border: "1px solid black",
          zIndex: 10000
        }
      }
    },

    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 10000
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "secondary.main",
          "&.Mui-selected": {
            color: "white !important",
            backgroundColor: "primary.main"
          }
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          backgroundColor: "#fff",
          border: "1px solid currentColor",
          "&:hover": {
            boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)"
          }
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: {
          paddingBottom: "1px",
          backgroundColor: colorChipAvailableBad,
          color: "white",
          fontSize: "12px",
          fontWeight: "bold",
          "&.MuiChip-available": {
            backgroundColor: colorChipAvailableGood,
            fontSize: "14px"
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(55,55,155,0.01)",
          "&:hover:not(.Mui-disabled)": {
            backgroundColor: "#f4f4f4"
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          pointerEvents: "none",
          fontSize: "13px"
        },
        arrow: {
          pointerEvents: "none"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: fontSizeH5
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: fontSizeH5
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        nativeInput: {
          opacity: "0 !important" // case for "boomers"
        }
      }
    }
  }
};
