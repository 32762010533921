import { ISelectData } from "@/interfaces/ISelectData";

export function convertHexToRGBA(hex: string, alpha = 1) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Funktion zum Löschen von Eigenschaften mit Wert "alle"
export const removePropertiesWithValueAlle = (obj: ISelectData) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "alle" || obj[key] === "" || (Array.isArray(obj[key]) && obj[key].length === 0)) {
      delete obj[key];
    }
  });
};

//TODO: will be used when prefix data works
export const formatPhoneNumber = (number: string) => {
  return number;
};

//input: any string
//output: A number in format XXXXXXX
export const shortingString = (str: string, len = 40): string => {
  if (typeof str === "boolean") {
    return str ? "Ja" : "Nein";
  }
  if (str.length > len) {
    return str.slice(0, len) + "...";
  }
  return str;
};

//input: any number or string
//output: A number in format XXXXXXX
export const cleanNumberOrString = (value: number | string): number => {
  let numericValue: number;
  if (typeof value === "string") {
    const cleanedValue = value.replace(/[^0-9.,-]/g, "");
    numericValue = parseFloat(cleanedValue.replace(",", "."));
    if (isNaN(numericValue)) {
      throw new Error("Invalid numeric value provided.");
    }
  } else {
    numericValue = value;
  }
  return numericValue;
};

//input: any number or string as brutto price. even with special characters.
//output: A netto price in format XXX.XXX,00 €
export const calculateNetPrice = (value: number | string, decimalPlaces = 0, vatRate = 19): string => {
  const numericValue = cleanNumberOrString(value);
  const netPrice = numericValue / (1 + vatRate / 100);
  return (
    new Intl.NumberFormat("de-DE", {
      style: "decimal",
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }).format(netPrice) + ",- €"
  );
};

//input: any number or string. even with special characters.
//output: A string in format XXX.XXX,00
export const formatNumber = (value: number | string, decimalPlaces = 0): string => {
  let numericValue: number;

  if (typeof value === "string") {
    const cleanedValue = value.replace(/[^\d.,-]/g, "");
    numericValue = parseFloat(cleanedValue.replace(".", "").replace(",", ".").replace(".", ""));
    if (isNaN(numericValue)) {
      throw new Error("Invalid numeric value provided.");
    }
  } else {
    numericValue = value;
  }

  return new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(numericValue);
};

//input: any number or string as price. even with special characters.
//output: A price in format XXX.XXX,00 €
export const formatPrice = (value: number | string, decimalPlaces = 0): string => {
  return formatNumber(value, decimalPlaces) + ",- € ";
};

//subtext for netto prices
export const getNettoPriceString = (value: number | string, decimalPlaces = 0, isVat: boolean, vat: number): string => {
  const nettoPrice = calculateNetPrice(value, decimalPlaces, vat);
  return isVat ? `${nettoPrice} (Netto), ${vat}% MwSt.` : "MwSt. nicht ausweisbar";
};

//create a query string by params
export const createQueryParams = (params: Record<string, any>) => {
  const queryParams = new URLSearchParams();
  Object.keys(params || {}).forEach((key) => {
    if (Array.isArray(params[key])) {
      queryParams.append(key, params[key].join(","));
    } else {
      queryParams.append(key, params[key]);
    }
  });
  return queryParams.toString();
};

//parse query string back to an object
export const parseQueryParams = (query: string): ISelectData => {
  const params = new URLSearchParams(query);
  const result: ISelectData = {};

  params.forEach((value, key) => {
    if (value === "") {
      return;
    }

    if (value.includes(",")) {
      result[key] = value.split(",").map((v) => (isNaN(Number(v)) ? v : Number(v)));
    } else {
      result[key] = isNaN(Number(value)) ? value : Number(value);

      if (!key.endsWith("F") && !key.endsWith("T")) {
        result[key] = [result[key]];
      }
    }
  });

  return result;
};
