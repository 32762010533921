import React, { useEffect } from "react";

interface GoogleAnalyticsProps {
  trackingId: string | undefined;
}

const clearGoogleAnalyticsCookies = () => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    if (name.trim().startsWith("_ga") || name.trim().startsWith("_gid") || name.trim().startsWith("__utma") || name.trim().startsWith("__utmb") || name.trim().startsWith("__utmc") || name.trim().startsWith("__utmz") || name.trim().startsWith("__utmv")) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }
};

const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({ trackingId }) => {
  useEffect(() => {
    if (!trackingId) {
      clearGoogleAnalyticsCookies();
      return;
    }

    const scriptId = "google-analytics-script";

    // check if script already exists
    const existingScript = document.getElementById(scriptId);
    if (existingScript) return;

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);

    const scriptContent = document.createElement("script");
    scriptContent.id = `${scriptId}-content`;
    scriptContent.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingId}');
    `;
    document.head.appendChild(scriptContent);

    return () => {
      const scriptToRemove = document.getElementById(scriptId);
      const scriptContentToRemove = document.getElementById(`${scriptId}-content`);
      if (scriptToRemove) document.head.removeChild(scriptToRemove);
      if (scriptContentToRemove) document.head.removeChild(scriptContentToRemove);
      clearGoogleAnalyticsCookies();
    };
  }, [trackingId]);

  return null;
};

export default GoogleAnalytics;
