import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { themeAudi } from "./themeAudi";

const htmlElements = ["div", "p", "h2", "h3", "h4", "h5", "h6", "ul", "ol", "li", "table", "tr", "td", "th", "header", "footer", "section", "article", "nav", "aside", "main", "figure", "figcaption", "form", "fieldset", "legend", "label", "input", "textarea", "button", "select", "option", "pre", "code", "blockquote", "hr"];

export const customStyles = htmlElements.reduce(
  (styles, element) => {
    styles[element] = { padding: 0, margin: 0 };
    return styles;
  },
  {} as { [key: string]: { padding: number; margin: number } }
);

export const getCurrentTheme = (pathname: string, customerConfiguration: ICustomerConfiguration | null, defaultTheme: any) => {
  switch (pathname) {
    case customerConfiguration?.pages?.detailSearch:
      return defaultTheme;
    case customerConfiguration?.pages?.detailSearch + "/audi":
      return themeAudi;
    default:
      return defaultTheme;
  }
};
