import { useEffect } from "react";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { Box } from "@mui/material";
// import { loadPriceRating } from "./AutoUncleWidgets";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

const CustomButton = styled(Box)(({ theme }) => ({
  "& .auws-button": {
    width: "100%",
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    fontSize: `${theme.typography.h4.fontSize} !important`,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark} !important`
    },
    fontWeight: "600 !important"
  }
}));

interface IAuEmbedConfig {
  id: string;
  departmentId?: string;
  onFormOpen?: (details: any) => void;
  onCustomerDetailsSent?: (details: any) => void;
  customData?: object;
  data?: any;
  noDelayedCtaPage?: boolean;
  pageType?: string;
  previewUrl?: string;
}

interface IAuEmbed {
  pushedUrls: string[];
  pushUrl: (url: string) => void;
  config: IAuEmbedConfig;
}

interface IAuws {
  load: () => void;
}

declare global {
  interface Window {
    AuEmbed: IAuEmbed;
    auws?: IAuws;
  }
}

interface IAutoUncleInit {
  customerConfig: ICustomerConfiguration;
}

const AutoUncleInit: React.FC<IAutoUncleInit> = ({ customerConfig }) => {
  const location = useLocation();
  const id = customerConfig.api?.autoUncle?.id;
  // const id = "HfO98RH667"; // TEST
  const departmentId = customerConfig.api?.autoUncle?.departmentId || "";

  if (!id) {
    return null;
  }

  useEffect(() => {
    if (document.getElementById("autouncle-script")) {
      return;
    }

    // configs
    setAutoUncleConfig(id, departmentId);

    // check for viewport meta tag
    if (!document.head.querySelector("meta[name=viewport]")) {
      console.warn('The AutoUncle Universal Script cannot detect any <meta name="viewport"> tag. Ensure that the script is added after this tag!');
    }

    // create data for the script
    const scriptData = {
      depId: window.AuEmbed.config.departmentId,
      device: window.matchMedia("only screen and (max-width: 760px)").matches ? "mobile" : "desktop",
      pageType: window.AuEmbed.config.pageType,
      url: window.AuEmbed.config.previewUrl || document.location.href
    };

    // create and insert the script element
    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = `https://www.autouncle.de/de/customers/${encodeURIComponent(window.AuEmbed.config.id)}/website_script.js?d=${encodeURIComponent(JSON.stringify(scriptData))}`;
    scriptElement.id = "autouncle-script";

    setTimeout(() => {
      document.head.appendChild(scriptElement);
    }, 500);
    //add widgets TODO
    // loadPriceRating();
  }, [location.pathname]);

  return null;
};

export default AutoUncleInit;

export function setStockId(stockId: number) {
  window.AuEmbed.config.data.stockId = stockId;
  window.auws?.load();
}

export function setAutoUncleConfig(id: string, departmentId: string) {
  window.AuEmbed = window.AuEmbed || {
    pushedUrls: [],
    pushUrl(url: string) {
      this.pushedUrls.push(url);
    },
    config: {
      id: id,
      departmentId: departmentId,
      onFormOpen(details) {
        console.log("details", details);
      },
      onCustomerDetailsSent(details) {
        console.log("details", details);
      },
      customData: {},
      data: {
        stockId: null
      },
      noDelayedCtaPage: null // true/false
    }
  };
}

export const ElementCalculator: React.FC = () => {
  return <CustomButton className="au-widget-trade-in" />;
};

export const ElementTestDrive: React.FC = () => {
  return <CustomButton className="au-book-test-drive-cta" />;
};

export const ElementSimilarCars: React.FC = () => {
  return <CustomButton className="au-widget-similar-cars" />;
};

export const ElementActionArea: React.FC = () => {
  return <CustomButton className="auws-cta-area" />;
};

export const ElementPriceAlert: React.FC = () => {
  return <CustomButton className="auws-price-alert-widget" />;
};

export const ElementPriceAlertSubscription: React.FC = () => {
  return <CustomButton className="auws-price-alert-cta" />;
};
