import React, { useEffect } from "react";

interface EtrackerProps {
  accountId: string | undefined;
}

const clearEtrackerCookies = () => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    if (name.trim().startsWith("ET_")) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }
};

const Etracker: React.FC<EtrackerProps> = ({ accountId }) => {
  useEffect(() => {
    if (!accountId) {
      clearEtrackerCookies();
      return;
    }

    const scriptId = "etracker-script";

    // check if script already exists
    const existingScript = document.getElementById(scriptId);
    if (existingScript) return;

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = `https://static.etracker.com/code/e.js`;
    script.async = true;
    document.head.appendChild(script);

    const scriptContent = document.createElement("script");
    scriptContent.id = `${scriptId}-content`;
    scriptContent.innerHTML = `
      (function(){
        var _etracker = window._etracker = window._etracker || [];
        _etracker.push(['setAccount', '${accountId}']);
        _etracker.push(['trackPageView']);
      })();
    `;
    document.head.appendChild(scriptContent);

    return () => {
      const scriptToRemove = document.getElementById(scriptId);
      const scriptContentToRemove = document.getElementById(`${scriptId}-content`);
      if (scriptToRemove) document.head.removeChild(scriptToRemove);
      if (scriptContentToRemove) document.head.removeChild(scriptContentToRemove);
      clearEtrackerCookies();
    };
  }, [accountId]);

  return null;
};

export default Etracker;
